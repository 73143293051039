<template>
  <div class="Login">
    <img src="../../assets/back_icon.png" @click="$router.go(-1)" class="back_icon" />
    <img src="../../assets/enter_otp_logo.png" class="login_pwd_logo" />
    <div class="title">{{ $t('enterOtp') }}</div>
    <div class="hint">{{ $t("enterOtpTips") }}</div>
    <div class="phone">{{ form.phone }} <span v-if="time > 0">{{ time }}</span></div>

    <CodeInput
      :loading="loading"
      class="input"
      :fields="4"
      :fieldWidth="45"
      :fieldHeight="45"
      v-on:complete="onComplete"
    />

    <div class="send_code" v-if="isTimeEnd">
      <div class="tips">{{ $t('sendCodeTips') }}</div>
      <div class="val" @click="getSmsCode(form.type)">{{ $t('sendIvrCode') }}</div>
      <div class="val" @click="getIvrCode(form.type)">{{ $t('sendSmsCode') }}</div>
    </div>

    <div class="buttons">
      <mu-button full-width color="#E7AD5C" textColor="#192C4E" @click="submit">{{ $t("submit") }}</mu-button>
    </div>

  </div>
</template>

<script>
import { phoneRules, validateCodeRules } from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import { session, registeredUser, password } from '../../utils/api'
import { geolocation } from '../../utils/index'
import mixin from './mixin'
import CodeInput from "vue-verification-code-input";
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
export default {
  name: "Code",
  mixins: [mixin],
  components: {
    CodeInput
  },
  data() {
    return {
      isValidateCode: 'Y',
      loading: false,
      eye_type: 'password',
      navbar: 0,
      code: '',
    };
  },
  computed: {
    ...mapState(["record", "brand"]),
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
  },
  watch: {
    isTimeEnd: {
      handler(val) {
        if (val) {
          if (this.form.type == "LOGIN" || this.form.type == "REGISTER") {
            let codeValidatecount = this.brand.codeValidatecount || 0
            if (codeValidatecount != 0 && this.getCodeLength >= codeValidatecount) {
              this.isValidateCode = 'N'
              this.submit()
            }
          }
        }
      },
      immediate: true
    }
  },
  created() {
    console.log(this.record)
    this.form.type = this.record.codeType;
    this.form.phone = this.record.codePhone;
    this.form.password = this.record.codePassword;
    this.form.comfirmPassword = this.record.codeComfirmPassword;
  },
  mounted() {
    this.getSmsCode(this.form.type)
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN"]),
    onComplete(val) {
      this.form.validateCode = val
      this.submit()
    },
    async submit() {
      this.isCheck = true;
      if (this.isValidateCode == 'Y') {
        var error = this.validation(['validateCode']);
        if (error) return this.$toast(error);
      }
      if (this.form.type == "LOGIN") {
        this.codeLogin()
      } else if (this.form.type == "REGISTER") {
        this.onRegister()
      } else if (this.form.type == "MODIFY_PIN") {
        this.onModifyPassword()
      }
    },
    async codeLogin() {
      this.isCheck = true;
      var error = this.validation(['phone', 'validateCode']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.$axios({
        method: "post",
        url: session,
        data: {
          loginType: 'CODE_LOGIN',
          phone: this.form.phone.replace(/\s/g, ""),
          pin: this.form.password,
          code: this.form.validateCode || 'N',
          deviceId: "H5",
          device: "H5",
          isValidateCode: this.isValidateCode,
          gps: await geolocation()
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
            await this.getUser();
          } else {
            this.$toast(e.status.msg);
            if (e.status.code == "993") {
              this.$router.push('/user/register')
            }
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
    },
    async onRegister() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('06_REGISTER', { content_name: '06_REGISTER' })
        }
        // Google Analytics  REGISTER
        if (localStorage['ga']) {
          window.gtag('event', 'REGISTER')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        phone: this.form.phone.replace(/\s/g, ""),
        pin: this.form.password,
        confirmPin: this.form.comfirmPassword,
        validateCode: this.form.validateCode || 'N',
        deviceId: "H5",
        device: "H5",
        isValidateCode: this.isValidateCode
      }
      await this.$axios({
        method: "post",
        url: registeredUser,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
            this.SET_RECORD_ITEM({
              key: "loginPhone",
              value: this.form.phone,
            });
            this.SET_RECORD_ITEM({
              key: "loginPassword",
              value: this.form.password,
            });
            await this.getUser();
          } else {
            if (e.status.code === '986') {
              this.SET_RECORD_ITEM({
                key: "loginPhone",
                value: this.form.phone,
              });
             this.$router.push('/user/login')
            }
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
      branch.logEvent('06_REGISTER', { metadata: data })
    },
    async onModifyPassword() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.$axios({
        method: "post",
        url: password,
        data: {
          phone: this.form.phone.replace(/\s/g, ""),
          pin: this.form.password,
          confirmPin: this.form.comfirmPassword,
          validateCode: this.form.validateCode,
          isValidateCode: 'Y'
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_RECORD_ITEM({
              key: "loginPhone",
              value: this.form.phone,
            });
            this.SET_RECORD_ITEM({
              key: "loginPassword",
              value: this.form.password,
            });
            this.$router.push({path: '/user/login'});
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
    }
  },
};
</script>

<style scoped lang="scss">
.Login {
  overflow: auto;
  background-color: #18252A;
  .back_icon {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 20px;
  }
  .login_pwd_logo {
    height: 210px;
    margin: 50px auto 0px auto;
    display: block;
  }
  .title {
    text-align: left;
    margin-top: 20px;
    font-size: 20px;
    color: #E7AD5C;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
  }
  .hint {
    font-size: 14px;
    color: #F4F4F4;
    margin: 0px 20px;
  }
  .phone {
    font-size: 14px;
    color: #E7AD5C;
    margin: 0px 20px;
    span {
      padding: 0px 8px;
      margin-left: 5px;
      color: #F4F4F4;
      background: #18252A;
      border-radius: 8px;
    }
  }
  .send_code {
    color: #F4F4F4;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
    .val {
      margin-top: 10px;
      color: black;
      text-decoration: underline;
    }
  }
  .input {
    margin: 40px auto;
  }
  .buttons {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    /deep/ .mu-raised-button {
      border-radius: 8px;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
}
</style>
